<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Product Category</label>
            <v-select
              v-model="selectedFilterProductCategory"
              :options="filterProductCategories"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Product
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(index)="data">
              {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell(active)="data">
              {{ data.item.active === 1 ? "Yes" : "No" }}
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onShowEditForm(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-product-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      class="mb-5"
      @ok="onConfirmSubmit"
      @show="onShowModalForm"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <Input
            initial-name="Product Code"
            initial-id-input="product_code"
            initial-input-type="text"
            :initial-input-value="product.product_code"
            :initial-rules="{ required: true }"
            @changeValue="onChangeValue"
          />

          <Input
            initial-name="Product Name"
            initial-id-input="product_name"
            initial-input-type="text"
            :initial-input-value="product.product_name"
            :initial-rules="{ required: true }"
            @changeValue="onChangeValue"
          />

          <ValidationProvider
            v-slot="{ errors }"
            name="category"
            vid="category"
            rules="required"
          >
            <b-form-group>
              <label for="category">Select Category</label>
              <v-select
                id="category"
                v-model="selectedProductCategory"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                name="idInput"
                :options="productCategories"
                placeholder="Select Category"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
          <div
            v-if="editMode && product.id !== 0"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="product.active"
              name="is_active"
              value="1"
              unchecked-value="0"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ editMode ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { MarketingOfficerProductService, AxiosError, ListService } from '@/services'
import formatter from '@/mixins/formatter'
import Input from '@/components/FormInput/InputType'
export default {
  name: 'MarketingOfficerProducts',

  middleware: ['auth', 'marketing-officer'],

  components: {
    Input
  },

  metaInfo () {
    return {
      title: 'Products'
    }
  },

  mixins: [formatter],

  data () {
    return {
      isBusy: false,
      editMode: false,
      product: {
        id: 0,
        product_code: '',
        product_name: '',
        active: 1,
        product_category: 0
      },
      selectedProductCategory: [],
      productCategories: [],
      selectedFilterProductCategory: [{ value: 'All', label: 'All' }],
      filter: {
        product_category: 'All'
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'product_category_name', label: 'category name', sortable: true },
          { key: 'product_code', sortable: true },
          { key: 'product_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.editMode ? 'Edit Product' : 'Add Product'
    },
    filterProductCategories () {
      return [{ value: 'All', label: 'All' }].concat(this.productCategories)
    }
  },

  watch: {
    'selectedProductCategory' (item) {
      this.product = { ...this.product, product_category: item.value }
    },

    'selectedFilterProductCategory' (item) {
      this.filter.product_category = item.value
      this.onProductCategoryFilterChange()
    }
  },

  mounted () {
    core.index()
    this.getProductCategories()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await MarketingOfficerProductService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_product_category=${this.filter.product_category}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getProductCategories () {
      await ListService.getProductCategories().then(({ data }) => {
        this.productCategories = data.map(({ id, product_category_name }) => {
          return { value: id, label: product_category_name }
        })
      })
    },

    onProductCategoryFilterChange () {
      this.$refs.table.refresh()
    },
    filterProductCategoryById (id) {
      const updateData = this.productCategories?.filter(el => el.value === id)
      return { value: updateData?.[0]?.value || 0, label: updateData?.[0]?.label || '' }
    },

    onShowModalForm () {
      if (!this.editMode) {
        this.product.id = 0
        this.product.product_code = ''
        this.product.product_name = ''
        this.product.product_category = 0
        this.product.active = 1
      }
    },

    onShowAddForm () {
      this.editMode = false
      this.selectedProductCategory = []
      this.$bvModal.show('modal-product-form')
    },

    onShowEditForm (row) {
      this.editMode = true
      const { id, product_code, product_name, product_category_id, active } = row
      this.selectedProductCategory = this.filterProductCategoryById(product_category_id)
      this.product = { id, product_code, product_name, active, product_category: product_category_id }
      this.$bvModal.show('modal-product-form')
    },

    async onConfirmSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          if (!this.editMode) {
            this.$swal.fire({
              title: 'Are you sure want to add this product ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Submit',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return this.onSubmitPostForm()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$swal.fire({
              title: 'Are you sure want to update this product ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Update',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return this.onSubmitPutForm()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          }
        }
      })
    },

    async onSubmitPostForm () {
      this.isBusy = true
      await MarketingOfficerProductService.post(this.product).then(response => {
        this.$bvModal.hide('modal-product-form')
        this.isBusy = false
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    },

    async onSubmitPutForm () {
      this.isBusy = true
      await MarketingOfficerProductService.put(this.product).then(response => {
        this.$bvModal.hide('modal-product-form')
        this.isBusy = false
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    },

    onChangeValue (value, name) {
      this.product[name] = value
    }
  }
}
</script>
